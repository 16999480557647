@font-face {
  font-family: "Aldrich";
  src: local("Aldrich"),
    url(./Assets/fonts/aldrich.regular.ttf) format("truetype");
  /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: "BankGothic";
  src: local("BankGothic"),
    url(./Assets/fonts/BankGothicBold.ttf) format("truetype");
  /* other formats include: 'woff2', 'truetype, 'opentype',
                                'embedded-opentype', and 'svg' */
}

@font-face {
        font-family: "Nasalization Regular";
        src: local("Nasalization Regular"),
          url(./Assets/fonts/Nasalization.otf) format("opentype");
        /* other formats include: 'woff2', 'truetype, 'opentype',
                                      'embedded-opentype', and 'svg' */
      }

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');


:root {
  --primary-color: #009b9b;
  --secondary-color: #ff0076;
  --tier-one: #f9bb02;
  --tier-two: #971aaf;
  --tier-three: #1d46d6;
  --tier-four: #02f72b;
  --header-text-color: #019b9b;
  --header-shadow: 0px 0px 6px rgba(1, 155, 155, 0.3), 2px 0px 50px #019b9b;
  --dark: #191919;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 16px;
}

html,
body,
#root {
  height: 100%;
  width: 100%;
  background: #000;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.html::-webkit-scrollbar {
        display: none;
}

.html {
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
}

body {
  -webkit-font-smoothing: antialiased;
}

#overlay::-webkit-scrollbar {
  display: none;
}

.fade-in-animate {
  animation: fadeIn 0.5s;
  -webkit-animation: fadeIn 0.5s;
  -moz-animation: fadeIn 0.5s;
  -o-animation: fadeIn 0.5s;
  -ms-animation: fadeIn 0.5s;
}

iframe {
  z-index: -10 !important;
}

.no-scroll {
    overflow: hidden;
}

#termsofservice {
        display: block;
        z-index: 100;
        position: fixed;
        top: 0;
        bottom: 0;
        width: 100vw;
        height: 100vh;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/*
    body {
        font-size: 16px;
        line-height: 1.6;
        color: #333;
    }

    @media (max-width: 768px) {
        body {
            font-size: 14px;
        }
    }

    @media (min-width: 768px) and (max-width: 1200px) {
        body {
            font-size: 16px;
        }
    }

    @media (min-width: 1200px) {
        body {
            font-size: 18px;
        }
    }
*/
