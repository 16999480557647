.button {
        border: none;
        background-color: var(--primary-color);
        clip-path: polygon(4% 0, 100% 0, 100% 75%, 96% 100%, 0 100%, 0 25%);
        cursor: pointer;
        transition: all 0.2s ease-in-out;
}

.button:hover {
        transform: scale(1.1);
}

.button > div {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 100%;
        padding: 0.5em 1em 0.4em;
        gap: 0.5em;
        font-family: "Roboto";
        text-transform: capitalize;
        color: white;
}

@media (max-width: 768px) {

        .button > div {
                padding: 0.5em 1em 0.4em;
        }
}
