.container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        z-index: 11;
}


.vectorPath {
        transition: all 0.3s ease-in-out;
}


.firstHovered {
        transform: translateX(-12px) skewX(45deg);
}

.secondHovered {
        transform-style: flat;
        transform: translateX(-39px) scaleX(1.5) skewX(45deg) ;
}

.thirdHovered {
        transform-origin: left;
        transform: translateX(-63px) scaleX(3.2) skewX(45deg);
}

.firstClicked {
        transform-origin: center;
        transform: rotate(45deg) translate(10px, 10px) skewX(45deg);
}

.secondClicked {
        display: none;
}

.thirdClicked {
        transform-origin: center;
        transform: translate(-10px, -8px) rotate(-45deg)  scaleX(3.5) skewX(45deg);
}
