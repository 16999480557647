.buttonContainer {
    font-size: 20px;
    background: linear-gradient(-45deg, transparent 5%, #f2e200 5%, #f2e200 95%, transparent 0%);
    border: 0;
    color: #1d1a3e;
    letter-spacing: 2px;
    outline: transparent;
    position: relative;
    padding: 6px 16px;
  }

  .buttonContainer:hover {
    filter: contrast(80%);
  }

  .buttonContainer:disabled {
    cursor: auto;
    background: linear-gradient(-45deg, transparent 5%, #918c50 5%, #918c50 95%, transparent 0%);
  }

  .orangeButton {
    background: linear-gradient(-45deg, transparent 5%, #ffcc0f 5%, #ff3d3d 95%, transparent 0%);
  }

  .orangeButton:disabled {
    background: linear-gradient(-45deg, transparent 5%, #8a7734 5%, #964d4d 95%, transparent 0%);
  }

  .greenButton {
    background: linear-gradient(-45deg, transparent 5%, #3dfff5 5%, #3dff6f 95%, transparent 0%);
  }

  .outlinedButton {
    cursor: pointer;
    position: relative;
    --path: 10px 0, 100% 0, 100% calc(100% - 10px), calc(100% - 10px) 100%, 0 100%, 0 10px;
    --border: 3px;
    padding: 8px 1em;
    font-size: 20px;
    font-weight: bold;
    display: inline-block;
    clip-path: polygon(var(--path));
    transition: 0.4s;
    background: linear-gradient(45deg, var(--primary-color), var(--primary-color)) border-box;
    font-family: "BankGothic";
  }

  .outlinedButton::after {
    content: "";
    position: absolute;
    inset: 0;
    background: #181429;
    width: calc(100% - 2px);
    height: calc(100% - 2px);
    left: 1px;
    top: 1px;
    clip-path: polygon(var(--path));
    z-index: -1;
    transition: 0.4s;
  }

  .outlinedButton:hover::after {
        background: linear-gradient(-45deg, var(--dark), var(--primary-color), var(--primary-color)) border-box;
  }


  .outlinedButton:hover {
    color: white !important;
    background: linear-gradient(-45deg, var(--primary-color), var(--primary-color)) border-box;
    text-shadow: var(--header-shadow);
  }
