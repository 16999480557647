.header {
  box-sizing: border-box;
  height: 10%;
  padding: 3em 2em 1em 2em;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 2;
}

.header > svg {
  z-index: 11;
}
.icon {
  width: 10em;
}

.svg {
  width: 24px !important;
  margin-left: 3px;
  z-index: 11;
}

.metaverse {
  margin-left: auto;
  margin-right: 1em;
}

.backdrop {
  z-index: 10;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  background-color: rgb(0, 0, 0);
  display: flex;
}

.backdrop.active {
  pointer-events: all;
  animation: expand 1s ease-in;
  clip-path: circle(200% at 4% 4%);
}

.backdrop.deactive {
  pointer-events: none;
  animation: shrink 1s ease-in;
  clip-path: circle(0% at 4% 4%);
}

.closeButton {
  border: none;
  background: none;
  background-image: url(../../Assets/images/closevideo.png);
  position: absolute;
  top: 2em;
  right: 2em;
  width: 4rem;
  height: 4rem;
  background-position: center;
  background-size: 100%;
  transition: all 0.2s ease-in-out;
}

.closeButton:hover {
  cursor: pointer;
  filter: brightness(2);
}

.menuItems {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  height: 80%;
  width: 60%;
  margin: auto;
  max-width: 600px;
}

.menuItems a {
  text-decoration: none;
}

.topCover {
  border-bottom: 4px solid var(--primary-color);
  border-radius: 40px;
  height: 1rem;
  max-width: 400px;
  min-width: 300px;
  margin-bottom: 2em;
}

.bottomCover {
  border-top: 4px solid var(--primary-color);
  border-radius: 40px;
  height: 1rem;
  max-width: 400px;
  min-width: 300px;
  margin-top: 2em;
}

.menuItem {
  font-family: "BankGothic";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 220%;

  text-align: center;
  letter-spacing: 0.05em;

  color: #eeeeee;
  text-transform: uppercase;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
}

.menuItem.disabled {
  color: #656565;
}

.menuItem:hover:not(.disabled) {
  cursor: pointer;
  color: var(--primary-color);
}

@keyframes expand {
  0% {
    clip-path: circle(0% at 4% 4%);
  }
  100% {
    clip-path: circle(200% at 4% 4%);
  }
}

@keyframes shrink {
  0% {
    clip-path: circle(200% at 4% 4%);
  }
  100% {
    clip-path: circle(0% at 4% 4%);
  }
}

@media (max-width: 768px) {
  .header {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding: 1em 1em 0 1em;
    height: 8%;
  }
  .icon {
    margin-left: 0.5em;
    width: 8em;
  }
  .metaverse {
    font-size: 16px !important;
    margin-right: 0.5em;
  }
  .mini {
    border: none;
    background: var(--secondary-color);
    margin-left: auto;
    margin-right: 10px;
    padding-top: 3px;
    clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
  }
  .dropdown {
    position: absolute;
    background-color: var(--secondary-color);
    width: 40px;
  }

  .mobileMenu {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1;
    transition: all 0.5s ease-in-out;
    margin-left: auto;
  }

  .menuContainer {
    transform: translateY(75%);
    position: absolute;
    right: 1.5em;
    padding: 0.5em 0.5em;
    width: 150px;
    margin-block: auto;
    animation: slideIn 0.5s ease-in-out;
  }

  .menuItem {
    font-size: 24px;
  }

  .closeButton {
    width: 3rem;
    height: 3rem;
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
    top: 1em;

    filter: brightness(2);
  }
}

@media (max-height: 900px) {
  .topCover {
    margin-bottom: 1em;
  }
  .bottomCover {
    margin-top: 1em;
  }
  .menuItem {
    font-size: 18px;
    line-height: normal;
  }
  .menuItems {
    height: 70%;
  }
}

@keyframes slideIn {
  0% {
    width: 0;
  }
  100% {
    width: 150px;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
